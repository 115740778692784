<template>
  <div class="page-products-edit">
    <back />
    <page-title title="Редактировать товар" description="На этой странице вы можете отредактировать свой товар" />
    <n-loader :loading="$var('load')" />

    <div class="container">
      <n-form @submit="submit">
        <div class="status"><span>Статус:</span> {{ $n.productStatus($form.get('status')).title }}</div>
        <n-image-upload v-if="isStore" :class="[{'danger-border': $form.errors('images')}]" :file="$form.get('images') || []"
                        multi @update:file="(v)=>$form.set('images', v)" />
        <div v-if="$form.errors('images')" class="danger-text">{{ $form.errors('images') }}</div>

        <div class="img-list">
          <div v-for="(file, i) in $form.get('images')" :key="i" class="wrap" @click="remove(i)">
            <div class="item">
              <img :src="getImage(file)" alt="">
            </div>
          </div>
        </div>

        <n-items>
          <n-input v-bind="$form.input('title')" title="Название товара" />
          <div class="cost-count">
            <n-input v-bind="$form.input('cost')" type="number" title="Стоимость товара" />
            <n-input v-if="isStore" v-bind="$form.input('count')" type="number" title="Количество" />
          </div>

          <n-input v-if="isStore" disabled :value="$form.get('category')" title="Категория товара" />

          <n-input v-if="isStore" v-bind="$form.input('code')" title="Артикул" />

          <n-form-item title="Описание товра">
            <textarea v-bind="$form.input('description')" @input="(e) => $form.set('description', e.target.value)" />
          </n-form-item>

          <n-button wide color="primary" type="submit">Сохранить</n-button>
          <n-button v-if="$form.get('status') !== 'canceled'" wide class="button-delete" @click="toggleArchive">В архив</n-button>
          <n-button v-if="$form.get('status') === 'canceled'" wide class="button-delete" @click="toggleArchive">Вернуть из архива</n-button>
          <n-button wide class="button-delete" @click="$var('remove', true)">Удалить</n-button>
        </n-items>
      </n-form>
    </div>

    <n-modal v-if="$var('remove')" :loading="$var('loadRemove')" @close="$var('remove', false)">
      <div class="remove-modal">
        <div class="title">Вы точно хотите удалить этот товар?</div>
        <div class="modal-buttons">
          <n-button @click="$var('remove', false)">Отмена</n-button>
          <n-button color="danger" @click="removeProduct">Удалить</n-button>
        </div>
      </div>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'PageProductsEdit',
  data() {
    return {
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isStore() {
      return $app.auth.user().hasStore
    },
  },
  created() {
    this.load()
    this.$form.rules({
      cost: [ 'required', ],
      title: [ 'required', ],
      images: [ 'required', ],
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.products.get(this.id).with('images').with('category').then((response) => {
        this.$form.init(response.data.content)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (!this.$form.check()) {
        return
      }
      this.$var('load', true)
      const oldImages = []
      const apis = $n.reduce(this.$form.get('images'), (result, file) => {
        if (!file.id) {
          result.push($api.files.create({ file, }))
        } else {
          oldImages.push(file.id)
        }
        return result
      }, [])

      if ($n.size(apis)) {
        Promise.all(apis).then((responses) => {
          const images = $n.reduce(responses, (result, item) => {
            result.push(item.data.content.id)
            return result
          }, [])
          this.save($n.concat(images, oldImages))
        }).catch(() => {
          this.$var('load', false)
        })
      } else {
        this.save()
      }
    },
    save(images) {
      console.log(images)
      const data = {
        storeId: $app.auth.user().store?.id,
        ...this.$form.get(),
        categoryId: this.$form.get('category')?.id,
        category: undefined,
        images,
      }
      $api.app.products.edit(this.id, data).then((response) => {
        $app.store.mutation('app.product', {})
        this.$router.push({ name: 'products.index', params: { id: response.data.content.id, }, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    remove(i) {
      if (this.isStore) {
        const array = this.$form.get('images')
        array.splice(i, 1)
        this.$form.set('images', array)
      }
    },
    getImage(file) {
      if (file.id) {
        return file.src
      }
      return URL.createObjectURL(file)
    },
    removeProduct() {
      this.$var('loadRemove', true)
      $api.app.products.delete(this.id).then((response) => {
        this.$router.push({ name: 'my.products', })
      }).finally(() => {
        this.$var('loadRemove', false)
      })
    },
    toggleArchive() {
      this.$var('load', true)
      const status = this.$form.get('status') === 'canceled' ? (this.$form.get('count') ? 'active' : 'sold') : 'canceled'
      $api.app.products.edit(this.id, { status, }).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-products-edit {
  padding-bottom: 10px;

  .status {
    margin: 10px 0 15px;
    font-size: 1.2em;
    span {
      opacity: .5;
      font-size: .8em;
    }
  }

  .remove-modal {
    .title {
      margin: 0 0 20px 0;
      font-size: 1.2em;
    }
    .modal-buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  .cost-count {
    display: flex;
    width: 100%;
    & > *:nth-child(1) { margin-right: 10px; max-width: 50%; }
    & > *:nth-child(2) { margin-left: 10px; }
  }

  .img-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .wrap {
      padding: 5px;
      width: 33.333333%;
    }

    .item {
      height: 74px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  textarea {
    font-size: 14px;
    padding: 12px 15px;
    width: 100%;
    min-height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;

    &:focus-visible {
      border: 1px solid var(--primary);
      outline: none;
    }

    &::placeholder {
      font-size: 14px;
      color: transparent;
    }
  }

  .n-form {
    .n-link {
      color: inherit;
    }
  }

  .danger-text {
    margin-top: 5px;
    color: var(--danger);
  }

  .danger-border {
    border: 1px solid var(--danger);
    border-radius: 10px;
  }

  .button-delete {
    margin-top: 20px;
  }
}
</style>
